/* 
 * ProductCard component styles
 * High-performance rendering with optimizations to prevent blur
 */

.product-card {
  width: 90%;
  max-width: 400px;
  min-height: 300px;
  margin: 2rem auto;
  border-radius: 12px;
  background-color: var(--container-color);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
    overflow: hidden;
  /* Prevent blur */
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Tabs container */
.product-card__tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--container-color);
}

/* Individual tab */
.product-card__tab {
  flex: 1;
  padding: 1rem 0.5rem;
  border: none;
  background: transparent;
  color: var(--text-color);
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: color 0.2s ease, background-color 0.2s ease;
  position: relative;
}

.product-card__tab i {
  font-size: 1.1rem;
  transition: transform 0.2s ease;
}

.product-card__tab:hover {
  color: var(--title-color);
}

.product-card__tab:hover i {
  transform: scale(1.1);
}

/* Active tab */
.product-card__tab--active {
  color: var(--title-color);
  font-weight: 600;
}

.product-card__tab--active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 24px;
  height: 3px;
  background-color: var(--title-color);
  transform: translateX(-50%);
  border-radius: 3px;
}

/* Content area */
.product-card__content {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.product-card__description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: var(--text-color);
  margin: 0;
}

/* Buttons */
.product-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  line-height: 1;
}

.product-card__button i {
  font-size: 1rem;
  transition: transform 0.2s ease;
}

.product-card__button:hover i {
  transform: translateX(3px);
}

/* Primary button */
.product-card__button--primary {
  background-color: var(--title-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.product-card__button--primary i {
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.product-card__button--primary:hover {
  background-color: var(--title-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.product-card__button--primary:hover i {
  transform: translateX(3px);
}

/* Dark theme styles */
body.dark-theme .product-card {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
}

body.dark-theme .product-card__tab {
  color: var(--text-color);
}

body.dark-theme .product-card__tab--active {
  color: var(--title-color);
}

body.dark-theme .product-card__button--secondary {
  background-color: var(--container-color);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

body.dark-theme .product-card__button--primary {
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--title-color);
  border: 1px solid var(--title-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

body.dark-theme .product-card__button--primary:hover {
  background-color: var(--title-color);
  color: var(--container-color);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
}

body.dark-theme .product-card__button--primary i {
  color: var(--title-color);
}

body.dark-theme .product-card__button--primary:hover i {
  color: var(--container-color);
}

/* Secondary button */
.product-card__button--secondary {
  background-color: var(--container-color);
  color: var(--title-color);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  padding: 0.75rem 1rem;
}

.product-card__button--secondary i {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  color: var(--title-color-dark);
}

.product-card__button--secondary span {
  font-weight: 500;
}

.product-card__button--secondary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.02);
}

.product-card__button--secondary:hover i {
  transform: scale(1.1);
  color: var(--title-color-dark);
}

/* Grid for buttons */
.product-card__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .product-card {
    max-width: 90%;
    margin: 1.5rem auto;
    min-height: auto;
  }
  
  .product-card__tab {
    padding: 0.8rem 0.25rem;
    font-size: 0.85rem;
  }
  
  .product-card__tab i {
    font-size: 1rem;
  }
  
  .product-card__content {
    padding: 1.25rem;
    gap: 1.25rem;
  }
  
  .product-card__description {
    font-size: 0.9rem;
  }
  
  .product-card__button {
    padding: 0.7rem 1rem;
    font-size: 0.85rem;
  }
  
  .product-card__grid {
    gap: 0.75rem;
  }
}

@media (max-width: 350px) {
  .product-card {
    max-width: 95%;
    margin: 1rem auto;
  }
  
  .product-card__tab {
    padding: 0.7rem 0.2rem;
    font-size: 0.8rem;
  }
  
  .product-card__tab i {
    font-size: 0.9rem;
  }
  
  .product-card__content {
    padding: 1rem;
    gap: 1rem;
  }
  
  .product-card__description {
    font-size: 0.85rem;
  }
  
  .product-card__button {
    padding: 0.6rem 0.75rem;
    font-size: 0.8rem;
  }
  
  .product-card__grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  }
  