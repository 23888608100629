.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.header-hidden {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

.scroll-header {
  background-color: var(--body-color);
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
}

/* Theme switcher and nav buttons styles */
.nav__buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.theme-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.theme-toggle-container:hover {
  transform: rotate(45deg);
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  overflow: hidden;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: color 0.3s ease;
  position: relative;
}

/* Active link */
.active-link,
.nav__link:hover {
  color: var(--title-color-dark);
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: auto;
    bottom: 0;
    background-color: var(--body-color);
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    padding: 0;
    left: 0;
    right: 0;
    transition: all 0.0s ease, opacity 0.0s ease;
  }

  .header-hidden {
    transform: translateY(100%); /* Move down instead of up for bottom-positioned header */
    opacity: 0;
    pointer-events: none;
  }

  .nav {
    height: var(--header-height);
    overflow: hidden;
    width: 93%;
    max-width: 100%;
    padding: 0 1.3rem;
    box-sizing: border-box;
  }

  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    max-width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: bottom 0.3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
  }

  /* Show Menu */
  .show-menu {
    bottom: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
    transition: color 0.3s;
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .nav__link {
    transition: color 0.3s ease;
  }

  .nav__buttons {
    gap: 0.8rem;
  }

  .theme-toggle-container {
    margin-right: 0.2rem;
    transition: all 0.3s ease;
  }

  .theme-toggle-container:hover {
    transform: rotate(45deg);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .header {
    bottom: 0;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .header-hidden {
    transform: translateY(100%);
  }

  .nav {
    padding: 0 0.1rem;
  }

  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}
