.cursor-particles {
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cursor-particles canvas {
  position: absolute;
  left: 0;
  top: 0;
}

/* Make sure that the particles don't interfere with page interactions */
.cursor-particles canvas:hover {
  pointer-events: none;
} 