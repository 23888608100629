.skills__container {
  grid-template-columns: repeat(2, 320px);
  column-gap: 2.5rem;
  justify-content: center;
  transform: scale(0.98);
  transform-origin: top center;
  overflow: visible;
}

.skills__content {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.75rem 3.5rem;
  border-radius: 1.25rem;
  transition: all 0.3s ease;
}

.skills__content:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
  transition: transform 0.3s ease;
}

.skills__data:hover {
  transform: translateX(5px);
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--title-color);
  transition: transform 0.3s ease;
}

.skills__data:hover .bx-badge-check {
  transform: scale(1.2);
  color: var(--title-color-dark);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--tiny-font-size);
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.skills__data:hover .skills__level {
  opacity: 1;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
    transform: scale(0.95);
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
    transform: scale(0.98);
  }

  .skills__content {
    padding: 1.5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}

/* Add dark theme styles at the end of the file */

/* Dark theme adjustments */
body.dark-theme .skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark-theme .skills__title {
    color: var(--title-color);
}

body.dark-theme .skills__box {
    color: var(--text-color);
}

body.dark-theme .skills__data i {
    color: var(--title-color);
}
