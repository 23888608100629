.theme-toggle {
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.theme-toggle:hover {
  color: var(--title-color-dark);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .theme-toggle {
    font-size: 1.1rem; /* Match size with menu toggle */
  }
} 