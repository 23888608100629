/* Custom Toastify CSS */
.Toastify__toast {
    border-radius: 0.75rem;
    font-size: var(--small-font-size);
    color: var(--text-color);
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .Toastify__toast--success {
    border-left: 5px solid green;
  }
  
  .Toastify__toast--error {
    border-left: 5px solid red;
  }
  
  .Toastify__close-button {
    color: var(--text-color);
  }
  