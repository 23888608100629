.work {
  padding: 10px;
  text-align: center;
}

.section__title {
  margin-bottom: 10px;
}

.section__subtitle {
  font-size: 1.25rem;
  color: gray;
  margin-bottom: 20px;
}

.gallery-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container {
  /* Styles are applied inline in the component */
}

/* Ensure Slick container has proper height */
.slick-slider, .slick-list, .slick-track {
  height: 100%;
}

.slick-slide > div {
  height: 100%;
}

/* Custom styles for slick arrows */
.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  background-color: var(--title-color);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.slick-arrow.slick-prev {
  left: 10px;
}

.slick-arrow.slick-next {
  right: 10px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  background-color: var(--title-color-dark);
  opacity: 1;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

/* Styles for dots */
.slick-dots {
  bottom: 10px;
}

.slick-dots li button:before {
  color: var(--title-color);
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: var(--title-color);
  opacity: 1;
}

/* For dark theme */
body.dark-theme .slick-dots li button:before {
  color: var(--title-color);
}

body.dark-theme .slick-arrow.slick-next,
body.dark-theme .slick-arrow.slick-prev {
  background-color: var(--title-color);
}

body.dark-theme .slick-arrow.slick-next:hover,
body.dark-theme .slick-arrow.slick-prev:hover {
  background-color: var(--title-color-dark);
}

/* Styles for custom arrows */
.arrows {
  height: 20px;
  width: 20px;
  color: white; /* Arrow color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .slider-container {
    padding: 0;
  }
}