/*=============== TEXT SELECTION PREVENTION FOR MOBILE ONLY ===============*/
/* This CSS prevents text selection on mobile devices only */

@media screen and (max-width: 767px) {
  body {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    -webkit-touch-callout: none; /* iOS Safari */
  }
  
  /* Override for inputs and textareas to allow selection where needed */
  input, 
  textarea {
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -webkit-touch-callout: default;
  }
}

/* Allow text selection on tablets and desktops (width >= 768px) */
@media screen and (min-width: 768px) {
  body {
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    -webkit-touch-callout: default;
  }
} 