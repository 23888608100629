.contact__container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 5rem;
  padding-bottom: 3rem;
  transform: scale(0.98);
  transform-origin: top center;
  overflow: visible;
}

.contact__title {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.contact__info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 280px;
}

.contact__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.75rem;
  text-align: center;
  transition: all 0.3s ease;
}

.contact__card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.contact__card-icon {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-25);
  transition: transform 0.3s ease;
}

.contact__card:hover .contact__card-icon {
  transform: scale(1.2);
  color: var(--title-color-dark);
}

.contact__card-title,
.contact__card-data {
  font-size: var(--small-font-size);
}

.contact__card-title {
  font-weight: var(--font-medium);
}

.contact__card-data {
  display: block;
  margin-bottom: var(--mb-0-75);
}

.contact__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
  transition: all 0.3s ease;
}

.contact__button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.contact__button:hover .contact__button-icon {
  transform: translate(0.25rem);
}

.contact__button:hover {
  color: var(--title-color-dark);
}

.contact__form {
  width: 360px;
}

.contact__form-div {
  position: relative;
  margin-bottom: var(--mb-2);
  height: 4rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: none;
  color: var(--text-color);
  outline: none;
  border-radius: 0.75rem;
  padding: 1.5rem;
  z-index: 1;
  transition: border-color 0.3s ease;
}

.contact__form-input:focus {
  border-color: var(--title-color-dark);
}

.contact__form-tag {
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  font-size: var(--smaller-font-size);
  padding: 0.25rem;
  background-color: var(--body-color);
  z-index: 10;
}

.contact__form-area {
  height: 11rem;
}

.contact__form-area textarea {
  resize: none;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .contact__container {
    column-gap: 2.5rem;
    transform: scale(0.95);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .contact__container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
    transform: scale(0.98);
  }

  .contact__info {
    justify-content: center;
  }

  .contact__form {
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .contact__info {
    grid-template-columns: 1fr;
  }

  .contact__form {
    width: 100%;
  }
}

/* Add dark theme styles at the end of the file */

/* Dark theme adjustments */
body.dark-theme .contact__card {
    background-color: var(--container-color);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

body.dark-theme .contact__card-title,
body.dark-theme .contact__card-data {
    color: var(--title-color);
}

body.dark-theme .contact__form-input {
    background-color: transparent;
    border: 2px solid var(--text-color);
    color: var(--title-color);
}

body.dark-theme .contact__form-input::placeholder {
    color: var(--text-color);
}

body.dark-theme .contact__form textarea {
    background-color: transparent;
}
